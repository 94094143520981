<template>
  <div>
    <section>
      <div class="container">
        <div class="row justify-content-center">

          <div class="col-lg-7 text-center mb-5">
            <h1>LIFESTYLE</h1>
            <p>Immerse yourself in the art of indulgence, where luxury living takes centre stage.<br> Discover secluded
              holiday destinations, exquisite wines, the finest restaurants, private events, flawless interior decorating,
              inspiring landscaping designs and more. Embrace the extraordinary and delve into a lavish lifestyle.</p>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="https://fusionstoragelive.blob.core.windows.net/images/Listing/Office,801860/Photos,58895/pic_58895382.jpg" style="border-bottom-right-radius: 30px;"
                      class="mw-100" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>The Ultimate Guide to Buying a Luxury Holiday Home</h3>
                  <p>Owning a luxury holiday home in South Africa does more than give you access to more than the country’s beautiful landscapes, diverse cultures and vibrant lifestyle – it provides you with a solid investment that you can enjoy yourself or rent out to other holidaymakers from across the globe.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/ultimate-guide-to-buying-a-luxury-holiday-home"
              id="lifestyle-cta">READ MORE</a>
          </div>

          <div class="col-12 pb-5">
            <div class="p-3 bg-light rounded">
              <div class="row">
                <div class="col-lg-5 col-md-6 p-3">
                  <div class="h-100">
                    <img src="/images/lifestyle/embrace-luxury-interior-design-trends.jpg" style="border-bottom-right-radius: 30px;"
                      class="mw-100" />
                  </div>
                </div>
                <div class="col-lg-7 col-md-6 pe-lg-5 pt-4">
                  <h3>Embrace Luxury Interior Design Trends</h3>
                  <p>Luxury properties need to inspire from both an exterior and interior perspective. Investors should consider the latest interior design trends to ensure that their property increases in value over time.</p>
                </div>
              </div>
            </div>
            <a class="social-links switch-bg-dblue mb-0 cta-link" href="/article/embrace-luxury-interior-design-trends"
              id="lifestyle-cta">READ MORE</a>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {
      property: {

      }
    };
  },
};
</script>